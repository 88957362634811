import { readState } from "@/__main__/app-state.mjs";
import { isInitial } from "@/__main__/constants.mjs";
import type { DeadlockHeroStats } from "@/game-deadlock/models/heroesStats.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

interface DeadlockHero extends DeadlockHeroStats {
  heroName: string;
}
export function useHeroesStats(): {
  loading: boolean;
  stats: DeadlockHero[];
} {
  const {
    deadlock: { heroes, heroesStats },
  } = useSnapshot(readState);

  return {
    loading: !!heroesStats[isInitial],
    stats: (heroesStats || []).map((hero) => {
      const heroName = heroes[hero.heroId]?.heroName || "Unknown";
      return {
        ...hero,
        heroName,
      };
    }) as DeadlockHero[],
  };
}
