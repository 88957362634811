import { css } from "goober";

export const TableContainer = () => css`
  .hero-meta {
    display: flex;
    align-items: center;
    gap: var(--sp-3);

    .hero-img {
      position: relative;
      background: linear-gradient(
        to bottom,
        var(--shade5-75),
        var(--shade7-50)
      );
      border-radius: var(--br);
      contain: paint;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: var(--br);
        box-shadow: inset 0 0 0 1px var(--shade3-50);
      }
    }
  }
`;
